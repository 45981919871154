.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.arrow-desc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid black;
}

.arrow-asc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid #f00;
}

.ml-1 {
  margin-left: ($spacer * 0.25) !important;
}

.ml-2 {
  margin-left: ($spacer * 0.5) !important;
}

.ml-4 {
  margin-left: ($spacer * 1) !important;
}

.mr-2 {
  margin-right: ($spacer * 0.5) !important;
}

.mt-2 {
  margin-top: ($spacer * 0.5) !important;
}

.filter-multi-search-wrapper {
  flex: 1;
}

.input-group > .react-tag-input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  border: 1px solid #ced4da;
  padding-right: 35px;
}

.react-tag-input__input {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.react-tag-input__tag__content {
  font-size: 0.8125rem;
  font-weight: 400;
}

.red-dot-container {
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;

    width: 10px;
    height: 10px;

    background-color: #ff0000;

    border-radius: 5px;
  }
}

.white-btn {
  display: inline-block;
  width: 140px;
  height: 37px;
  padding: 4px 10px;
  background: #9EAEFA0D;
  border: 1px solid #556ee6;
  
  &:hover {
    background: rgba(85, 110, 230, 0.15);
    border: 1px solid #556ee6;
  }

  img {
    margin-right: 5px;
    height: 14px;
  }

  span {
    color: #556ee6;
  }
}
